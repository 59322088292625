<template>
  <div id="app">
    <div class="headBox" v-if="!flag">
      <myhead></myhead>
    </div>
    <div class="headBox2" v-else>
      <mymhead></mymhead>
    </div>
    <div class="img" v-if="!flag">
      <img
        class="pxToRem2"
        @click="$router.push('/')"
        src="./assets/BOINIGANVANITA.png" />
    </div>
    <router-view />
  </div>
</template>

<script>
import myhead from "@/components/public/head";
import mymhead from "@/components/public/mhead";
export default {
  name: "HomeView",
  components: {
    myhead,
    mymhead,
  },
  data() {
    return {
      flag: false,
    };
  },
  created() {
    this.flag = navigator.userAgent.match(
      /(phone|pad|pod|iphone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQOBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOs|Symbian|Windows Phone)/i
    );
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.headBox {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  height: 80px;
  background-color: white;
}
.headBox2 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  height: 130px;
  background-color: white;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.pxToRem2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.img {
  position: sticky;
  top: 14px;
  z-index: 51;
  width: 249px;
  height: 34px;
  margin: 81px auto 95px auto;
}

.el-select-dropdown__item span {
  display: block !important;
  text-align: right !important;
}
.mouse-cover-canvas {
  left: 50% !important;
  top: 300px !important;
}
</style>
