<template>
  <div class="home" style="position: relative">
    <div class="head">
      <div class="headLeft">
        <span
          class="el-icon-s-unfold unfold"
          @click="showListBox = true"></span>
      </div>
      <div class="headRight" @click="$router.push('/msearch')">
        <img class="pxToRem1" src="../../assets/搜索.png" />
      </div>
    </div>
    <div class="img2">
      <img
        class="pxToRem2"
        @click="$router.push('/mhome')"
        src="../../assets/BOINIGANVANITA.png" />
    </div>
    <div
      class="listBox"
      v-show="showListBox"
      @change="handleCurrentChange('1')"
      @touchmove="touchmove"
      @touchstart="touchstart"
      @touchend="touchend"
      @mouseleave="touchend">
      <div class="head">
        <div class="headLeft">
          <span
            class="el-icon-close unfold"
            @click="showListBox = false"></span>
        </div>
        <div class="headRight" @click="$router.push('/msearch')">
          <img class="pxToRem1" src="../../assets/搜索.png" />
        </div>
      </div>
      <div class="img2">
        <img
          class="pxToRem2"
          @click="showListBox = false"
          src="../../assets/BOINIGANVANITA.png" />
      </div>
      <div class="pxToRem3">
        <div
          :style="`border-bottom: ${
            item.id === showTagId ? '1px solid black' : 'none'
          };`"
          class="pxToRem4"
          v-for="item of list.categories"
          @mouseenter="getListCategory(item.id)"
          :key="item.name">
          {{ item.name }}
        </div>
        <div
          class="pxToRem5"
          @mouseleave="showTagId = null"
          @mouseenter="showTagId = 'img'"
          :style="`border-bottom: ${
            showTagId == 'img' ? '1px solid black' : 'none'
          };`">
          <img
            @click="toListBrand"
            class="headImg pxToRem6"
            src="../../assets/BOINIGANVANITA.png" />
        </div>
      </div>
      <div class="selectItemBox">
        <div class="pxToRem11" v-if="listCategoryArr && listCategoryArr.length">
          <div class="el-icon-back back" @click="listCategoryArr = []"></div>
          <div
            @click="toListSpu(item.id)"
            v-for="item of listCategoryArr.slice(0, 4)"
            class="pxToRem9">
            {{ item.name }}
          </div>
        </div>
        <div class="pxToRem8" v-if="listCategoryArr && listCategoryArr.length">
          <div
            @click="toListSpu(item.id)"
            v-for="item of listCategoryArr.slice(4)"
            class="pxToRem10">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeIndex, listCategory } from "@/apis/home.js";
import foot from "@/components/public/foot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      keyword: "",
      showTagId: "",
      list: [],
      listCategoryArr: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
      showListBox: false,
      page: { pageSize: 15, pageNum: 1, orderByColumn: "", isAsc: "" },
    };
  },
  created() {
    homeIndex().then((res) => {
      this.list = res.data.data;
    });
    listCategory().then((res) => {
      this.listCategoryArr = res.data.data;
    });
  },
  methods: {
    toListBrand() {
      this.showListBox = false;
      this.$router.push("/mlistBrand");
    },
    getListCategory(id) {
      this.showTagId = id;
      listCategory({ categoryId: id }).then((res) => {
        this.listCategoryArr = res.data.data.categories;
      });
    },
    showListBoxFn(id) {
      this.showTagId = id;
      this.getListCategory(id);
      // this.showListBox = true;
    },
    toListSpu(id) {
      this.showListBox = false;
      if (this.$route.path == "/mlistSpu" && this.$route.query.id == id) return;
      this.$router.push("/mlistSpu?id=" + id);
    },
    touchstart(e) {
      window.scrollTo(0, 0);
    },
    touchmove(e) {
      window.scrollTo(0, 0);
    },
    touchend(e) {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: white;
  // padding: 27px 70px 0 70px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 16px;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  padding: 10px 12px;
  .unfold {
    font-size: 20px;
  }
  .categories {
    text-align: left;
    margin-right: 29px;
    font-size: 12px;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .headImg {
    width: 73px;
    height: 10px;
    cursor: pointer;
  }
}
.headRight {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.img2 {
  width: 161px;
  height: 22px;
  margin: 40px auto 50px auto;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.listBox {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 784px;
  background: #fcfcfc;
  opacity: 1;
}
.pxToRem1 {
  width: 15px;
  margin: 10px 12px;
}
.pxToRem2 {
  width: 100%;
  height: 100%;
  // margin: 50px 38px;
  cursor: pointer;
}
.pxToRem3 {
  margin: 53px auto 50px auto;
  display: flex;
  flex-direction: column;
}
.pxToRem4 {
  text-align: left;
  margin: 0 auto 50px auto;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.pxToRem5 {
  margin: auto;
  display: flex;
  align-items: center;
}
.pxToRem6 {
  width: 73px;
  height: 10px;
  cursor: pointer;
}
.pxToRem7 {
  margin: 69px 0 0 25px;
  text-align: left;
}
.pxToRem11 {
  min-height: 200px;
  margin-left: 25px;
  text-align: left;
}
.pxToRem8 {
  margin: 22px 0 0 25px;
  text-align: left;
}
.pxToRem9 {
  font-size: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.pxToRem10 {
  font-size: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.search:hover {
  box-sizing: content-box;
  border-bottom: 1px solid black;
}
.back {
  margin-bottom: 50px;
}
.selectItemBox {
  width: 100%;
  background-color: #fcfcfc;
  position: absolute;
  top: 150px;
}
</style>
