var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"headLeft"},[_vm._l((_vm.list.categories),function(item){return _c('div',{key:item.name,staticClass:"categories",on:{"mouseenter":function($event){return _vm.showListBoxFn(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{style:(`border-bottom: ${
          _vm.showTagId == 'img' ? '1px solid black' : 'none'
        };`),on:{"mouseleave":function($event){_vm.showTagId = null},"mouseenter":function($event){_vm.showTagId = 'img'}}},[_c('img',{staticClass:"headImg",attrs:{"src":require("../../assets/BOINIGANVANITA.png")},on:{"click":function($event){return _vm.$router.push('/listBrand')}}})])],2),_c('div',{staticClass:"headRight",on:{"click":function($event){return _vm.$router.push('/search')}}},[_c('span',{staticClass:"search"},[_vm._v("搜索")]),_vm._v(" "),_c('img',{staticClass:"pxToRem1",attrs:{"src":require("../../assets/搜索.png")}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showListBox),expression:"showListBox"}],staticClass:"listBox",on:{"change":function($event){return _vm.handleCurrentChange('1')},"mouseleave":function($event){_vm.showListBox = false}}},[_c('div',{staticClass:"pxToRem3"},[_vm._l((_vm.list.categories),function(item){return _c('div',{key:item.name,staticClass:"pxToRem4",style:(`border-bottom: ${
          item.id === _vm.showTagId ? '1px solid black' : 'none'
        };`),on:{"mouseenter":function($event){return _vm.getListCategory(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{staticClass:"pxToRem5",style:(`border-bottom: ${
          _vm.showTagId == 'img' ? '1px solid black' : 'none'
        };`),on:{"mouseleave":function($event){_vm.showTagId = null},"mouseenter":_vm.hoverLogo}},[_c('img',{staticClass:"headImg pxToRem6",attrs:{"src":require("../../assets/BOINIGANVANITA.png")},on:{"click":function($event){return _vm.$router.push('/listBrand')}}})])],2),(_vm.listCategoryArr && _vm.listCategoryArr.length)?_c('div',{staticClass:"pxToRem7"},_vm._l((_vm.listCategoryArr.slice(0, 4)),function(item){return _c('div',{staticClass:"pxToRem9",on:{"click":function($event){return _vm.toListSpu(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(_vm.listCategoryArr && _vm.listCategoryArr.length)?_c('div',{staticClass:"pxToRem8"},_vm._l((_vm.listCategoryArr.slice(4)),function(item){return _c('div',{staticClass:"pxToRem10",on:{"click":function($event){return _vm.toListSpu(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }