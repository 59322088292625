<template>
  <div class="home">
    <div class="imgbox">
      <img
        :src="list.homes[0].h5ItemList[0].pictUrl"
        @click="toList2(list.homes[0].h5ItemList[0].configId)" />
      <div class="text" @click="toList2(list.homes[0].h5ItemList[0].configId)">
        {{ list.homes[0].h5ItemList[0].configName }}
      </div>
      <img
        :src="list.homes[0].h5ItemList[1].pictUrl"
        @click="toList2(list.homes[0].h5ItemList[1].configId)" />
      <div class="text" @click="toList2(list.homes[0].h5ItemList[1].configId)">
        {{ list.homes[0].h5ItemList[1].configName }}
      </div>
    </div>
    <div
      class="pic2"
      :style="`background-image: url(${list.homes[1].h5Background});background-size: 100% auto;`">
      <div class="center">
        <div
          :class="index == mouseenterIndex ? 'text1' : 'text2'"
          style="cursor: pointer"
          v-for="(item, index) of list.homes[1].h5ItemList">
          <span @click="mouseenterFn(index)">{{ item.configName }}</span>
        </div>
      </div>
      <div class="bottom">
        <img
          style="cursor: pointer"
          @click="
            $router.push(
              '/mdetail/' + list.homes[1].h5ItemList[mouseenterIndex].configId
            )
          "
          :src="list.homes[1].h5ItemList[mouseenterIndex].pictUrl" />
      </div>
      <div class="top">
        <div class="pxToRem1">
          {{ list.homes[1].name }}
        </div>
        <div class="pxtoRem2">
          <span class="pxToRem3" @click="toList(1)">立即查看</span>
        </div>
      </div>
    </div>
    <div
      class="pic3"
      :style="`background-image: url(${list.homes[2].h5Background});background-size: 100% auto;`">
      <div class="bottom">
        <div v-for="(item, index) of list.homes[2].h5ItemList">
          <img
            style="cursor: pointer;transition:all 3s"
            @touchstart="touchstart"
            @touchend="touchend"
            @click="tomshow(item)"
            v-if="showImgIndex == index"
            :src="item.pictUrl" />
          <div
            class="imgText"
            v-if="showImgIndex == index"
            style="visibility: hidden">
            {{ item.configName }}
          </div>
          <div class="changeIcon" v-if="showImgIndex == index">
            <span
              style="cursor: pointer"
              @click="changeImg(1)"
              class="jtz el-icon-back"></span>
            <span
              style="cursor: pointer"
              @click="changeImg(2)"
              class="jty el-icon-right"></span>
          </div>
          <div
            class="ts"
            v-if="showImgIndex == index"
            @click="$router.push(`/mshow?id=${item.configId}`)">
            <span class="pxToRem4">{{
              list.homes[2].h5ConfigJumps.configName
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { homeIndex } from "@/apis/home.js";
import foot from "@/components/public/mfoot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      difference: 0,
      startX: 0,
      differenceY: 0,
      startY: 0,
      list: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
    };
  },
  created() {
    homeIndex().then((res) => {
      this.list = res.data.data;
    });
  },
  methods: {
    tomshow(item) {
      if (this.difference < 20 && this.difference > -20) {
        this.$router.push(`/mshow?id=${item.configId}`);
      } else {
        this.difference = 0
      }
    },
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchend(e) {
      const endtX = e.changedTouches[0].clientX;
      const endtY = e.changedTouches[0].clientY;
      this.differenceX = endtX - this.startX;
      this.differenceY = endtY - this.startY;
      if (this.differenceX > 20&&this.differenceY<20&&this.differenceY>-20) {
        this.changeImg(1);
      }
      if (this.differenceX < -20&&this.differenceY<20&&this.differenceY>-20) {
        this.changeImg(2); //左滑
      }
    },
    mouseenterFn(index) {
      this.mouseenterIndex = index;
    },
    toList(index) {
      const id = this.list.homes[index].configJumps.configId;
      if (id) {
        this.$router.push(
          "/mlistSpu?id=" + this.list.homes[index].configJumps.configId
        );
      } else {
        this.$router.push("/mlistSpu");
      }
    },
    toList2(configId) {
      if (configId) {
        this.$router.push("/mlistSpu?id=" + configId);
      } else {
        this.$router.push("/mlistSpu");
      }
    },
    changeImg(n) {
      if (n == 2) {
        if (this.showImgIndex == this.list.homes[2].itemList.length - 1) {
          this.showImgIndex = 0;
          return;
        }
        this.showImgIndex = this.showImgIndex + 1;
      } else {
        if (this.showImgIndex == 0) {
          this.showImgIndex = this.list.homes[2].itemList.length - 1;
          return;
        }
        this.showImgIndex = this.showImgIndex - 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  margin-top: 150px;
}
.imgbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
  .text {
    font-size: 14px;
    margin: 26px 91px 81px 91px;
  }
}
.pic2 {
  // padding: 24px 31px 0 31px;
  padding-top: 50px;
  margin-bottom: 50px;
  .center {
    // margin: -34px 0 0 0;
    .text1 {
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 33px;
    }
    .text2 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 72px;
      // line-height: 50px;
    }
  }
  .bottom {
    img {
      width: 214px;
      margin: 0 auto 70px auto;
    }
  }
}
.pxToRem1 {
  font-size: 24px;
  margin-bottom: 27px;
}
.pxtoRem2 {
  padding-bottom: 60px;
}
.pxToRem3 {
  border-bottom: 1px solid black;
}

.pic3 {
  margin-top: 35px;
  .bottom {
    // padding-top: 53px;
  }
  img {
    width: 100%;
    height: 430px;
    // height: 600px;
  }
  .imgText {
    // width: 298px;
    color: white;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin: 20px 10px 20px 10px;
  }
  .changeIcon {
    // width: 1000px;
    color: white;
    text-align: left;
    margin: 0 20px 0 10px;
    display: flex;
    justify-content: right;
    font-size: 20px;
    font-weight: 1000;
    margin-bottom: 69px;
    .jty {
      margin-left: 15px;
    }
  }
  .ts {
    // width: 1000px;
    color: white;
    text-align: left;
    font-weight: 400;
    font-size: 17px;
    margin: 0 auto 0 10px;
    padding-bottom: 25px;
    cursor: pointer;
  }

  .pxToRem4 {
    border-bottom: 1px solid white;
  }
}
</style>
