<template>
  <div class="home">
    <div class="pxToRem1">
      <img
        class="pxToRem2"
        :src="item.h5Urls"
        v-for="item of brands"
        :key="item.name" />
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { listBrand } from "@/apis/home.js";
import foot from "@/components/public/mfoot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      keyword: "",
      brands: [],
      list: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
    };
  },
  created() {
    // homeIndex().then((res) => {
    //   this.list = res.data.data;
    // });
    listBrand().then((res) => {
      this.brands = res.data.data.brands;
    });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.home {
  padding: 150px 20px 0 20px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.pxToRem1 {
  width: 100%;
}
.pxToRem2 {
  width: 100%;
}
</style>
