<template>
  <div class="home">
    <div class="pxToRem1">
      <input
        placeholder="搜索（关键词等）"
        v-model="keyword"
         @keyup.enter="toDetail()"
        class="pxToRem2" />
    </div>
    <foot class="pxToRem3"></foot>
  </div>
</template>

<script>
// import { homeIndex } from "@/apis/home.js";
import foot from "@/components/public/foot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      keyword: "",
      list: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
    };
  },
  created() {
    // homeIndex().then((res) => {
    //   this.list = res.data.data;
    // });
  },
  methods: {
    toDetail(){
      if(this.keyword) this.$router.push('/listSpu?keyword='+this.keyword)
    },
    mouseenterFn(index) {
      this.mouseenterIndex = index;
    },
    changeImg(n) {
      if (n == 2) {
        if (this.showImgIndex == this.list.homes[2].itemList.length - 1) {
          this.showImgIndex = 0;
          return;
        }
        this.showImgIndex = this.showImgIndex + 1;
      } else {
        if (this.showImgIndex == 0) {
          this.showImgIndex = this.list.homes[2].itemList.length - 1;
          return;
        }
        this.showImgIndex = this.showImgIndex - 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input {
    outline: none;
}
.home {
  padding: 0 20px 0 20px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.pxToRem1 {
  width: 250px;
  padding-top: 50px;
  margin: auto;
}
.pxToRem2 {
  border-left: none;
  border-top: none;
  border-right: none;
}
.pxToRem3 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
