<template>
  <div class="home">
    <!-- <div class="back">
      <span @click="$router.go(-1)" class="el-icon-arrow-left"></span>
      <div @click="$router.go(-1)">返回</div>
    </div> -->
    <div class="detailInfo" style="position: relative">
      <span
        v-if="showSizeTable"
        @click="showSizeTable = false"
        style="
          position: fixed;
          top: 15px;
          right: 15px;
          z-index: 5;
          cursor: pointer;
        "
        class="el-icon-close"></span>
      <div
        v-if="showSizeTable"
        style="position: fixed; top: 0; left: 0; width: 100%; z-index: 4">
        <img style="width: 100%" :src="form.sizeTableUrl" />
      </div>
      <div class="pxToRem1">
        <!-- <pic-zoom
          style="width: 100%"
          :url="form.pictUrl"
          :scale="2"
          :scroll="true"></pic-zoom> -->
        <div class="imgListBox">
          <img
            :style="`position: absolute;z-index:${
              index == showIndex ? 45 : -1
            }`"
            @touchstart="touchstart"
            @touchend="touchend"
            v-for="(item, index) of form.h5AlbumList"
            class="pxToRem2"
            :src="item" />
          <span
            @click.stop="imgIndexChange('-')"
            class="el-icon-arrow-left arrowLeftImg"></span>
          <span
            @click.stop="imgIndexChange('+')"
            class="el-icon-arrow-right arrowRightImg"></span>
        </div>
      </div>
      <div class="pxToRem3">
        <div class="title">{{ form.name }}</div>
        <div class="pxToRem8">
          <div class="pxToRem9">颜色：</div>
          <div class="pxToRem8Item" v-for="item of attrsMaps[1]">
            {{ item.name }}/
          </div>
        </div>
        <div class="pxToRem10">
          <div
            :class="{ colorBorder: color === item.name ? true : false }"
            @click="changeImg(item.name, 1)"
            class="toRem"
            :style="`cursor:pointer;background-color: ${item.value}`"
            v-for="item of attrsMaps[1]"></div>
        </div>
        <div class="pxToRem11">
          <div class="pxToRem12">尺码范围：</div>
          <div class="pxToRem13">
            <div
              @click="changeImg(item.name, 2)"
              class="pxToRem14"
              :class="{ bakColor: sizeType === item.name ? true : false }"
              v-for="item of attrsMaps[2]">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="pxToRem4">
          <div
            @click="tag1 = !tag1"
            :class="{ bottomBorder: true }"
            class="pxToRem5">
            <span style="font-weight: bold">细节</span
            ><span
              :class="{
                'el-icon-arrow-down': tag1 ? false : true,
                'el-icon-arrow-up': tag1 ? true : false,
              }"></span>
          </div>
          <div class="pxToRem7">
            {{ tag1 ? form.detail : "" }}
          </div>
          <div
            @click="tag3 = !tag3"
            :class="{ bottomBorder: true }"
            class="pxToRem6">
            <span style="font-weight: bold">成分</span
            ><span
              :class="{
                'el-icon-arrow-down': tag3 ? false : true,
                'el-icon-arrow-up': tag3 ? true : false,
              }"></span>
          </div>
          <div class="pxToRem7">
            {{ tag3 ? form.components : "" }}
          </div>
        </div>
        <div style="display: flex">
          <div class="pxToRem15">
            <div
              class="pxToRem16"
              style="cursor: pointer"
              @click="tag2 == '商品描述' ? (tag2 = '') : (tag2 = '商品描述')">
              商品描述<span
                :class="{
                  'el-icon-arrow-down': tag2 == '商品描述' ? false : true,
                  'el-icon-arrow-up': tag2 == '商品描述' ? true : false,
                }"></span>
            </div>
            <div
              v-if="tag2 == '商品描述' && form.description"
              class="pxToRem17">
              <div
                class="pxToRem18"
                v-for="item of form.description.split('\r\n')">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="pxToRem19">
            <div
              class="pxToRem20"
              style="cursor: pointer"
              @click="
                tag2 == '尺码与版型' ? (tag2 = '') : (tag2 = '尺码与版型')
              ">
              尺码与版型<span
                :class="{
                  'el-icon-arrow-down': tag2 == '尺码与版型' ? false : true,
                  'el-icon-arrow-up': tag2 == '尺码与版型' ? true : false,
                }"></span>
            </div>
            <div v-if="tag2 == '尺码与版型'" class="pxToRem21">
              <div class="pxToRem22">
                <div>{{ form.sizeType }}</div>
                <div
                  @click="showSizeTable = true"
                  v-if="form.sizeTableUrl"
                  style="
                    border-bottom: 1px solid black;
                    cursor: pointer;
                    margin-top: 5px;
                  ">
                  尺码参考表
                </div>
              </div>
            </div>
          </div>
          <div class="pxToRem23">
            <div
              class="pxToRem24"
              style="cursor: pointer"
              @click="
                tag2 == '面料与保养' ? (tag2 = '') : (tag2 = '面料与保养')
              ">
              面料与保养<span
                :class="{
                  'el-icon-arrow-down': tag2 == '面料与保养' ? false : true,
                  'el-icon-arrow-up': tag2 == '面料与保养' ? true : false,
                }"></span>
            </div>
            <div
              v-if="tag2 == '面料与保养' && form.fabricCare"
              class="pxToRem25">
              <div
                class="pxToRem26"
                v-for="item of form.fabricCare.split('\r\n')">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="showImgBox pxToRem27">
      <div
        class="showImg pxToRem28"
        v-for="(item, index) of detailList"
        :style="`margin-right: ${(index + 1) % 3 == 0 ? '0px' : '20px'}`">
        <img :src="item" />
      </div>
    </div> -->

    <!-- <div class="youLike" v-if="moreList.length">
      <span class="pxToRem29">您可能会喜欢</span>
    </div>
    <div class="showImgBox">
      <div
        class="showImg pxToRem31"
        v-for="(item, index) of moreList"
        :style="`width: 20%; margin-bottom: 0; margin-top: 0;margin-right: ${
          (index + 1) % 4 == 0 ? '0px' : '47px'
        }`">
        <img
          style="cursor: pointer"
          @click="$router.push('/mdetail/' + item.spuId)"
          :src="item.pictUrl" />
        <div class="pxToRem30">{{ item.name }}</div>
      </div>
    </div> -->
    <foot></foot>
  </div>
</template>

<script>
import { getSpu } from "@/apis/home.js";
import foot from "@/components/public/mfoot";
import PicZoom from "vue-piczoom";
export default {
  name: "HomeView",
  components: {
    foot,
    PicZoom,
  },
  data() {
    return {
      showSizeTable: false,
      zkh: "<",
      ykh: ">",
      keyword: "",
      tag1: false,
      tag2: "商品描述",
      tag3: false,
      color: "",
      sizeType: "",
      list: [],
      listCatwalks: [],
      getCatwalks: [],
      headImg: "",
      form: {},
      detailList: [],
      attrsMaps: [],
      skuList: [],
      moreList: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
      total: 0,
      showIndex: 0,
      page: { pageSize: 20, pageNum: 1, orderByColumn: "", isAsc: "" },
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route() {
      this.getSpuList();
    },
  },
  created() {
    // homeIndex().then((res) => {
    //   this.list = res.data.data;
    // });
    this.getSpuList();
  },
  methods: {
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchend(e) {
      const endtX = e.changedTouches[0].clientX;
      const endtY = e.changedTouches[0].clientY;
      this.differenceX = endtX - this.startX;
      this.differenceY = endtY - this.startY;
      if (
        this.differenceX > 20 &&
        this.differenceY < 20 &&
        this.differenceY > -20
      ) {
        this.imgIndexChange("-");
      }
      if (
        this.differenceX < -20 &&
        this.differenceY < 20 &&
        this.differenceY > -20
      ) {
        this.imgIndexChange("+");
      }
    },
    getSpuList() {
      const id = this.$route.params.id;
      const params = {
        spuId: id,
      };
      getSpu(params).then((res) => {
        this.form = res.data.data;
        this.detailList = res.data.data.albumList || [];
        this.attrsMaps = res.data.data.attrsMaps || [];
        this.skuList = res.data.data.skuList || [];
        this.moreList = res.data.data.guessList || [];
      });
    },
    changeImg(name, type) {
      // if (type === 1) {
      //   this.color = name;
      // } else {
      //   this.sizeType = name;
      // }
      // this.skuList.forEach((e) => {
      //   if (e.colour === this.color && e.sizes === this.sizeType) {
      //     this.form.pictUrl = e.pictUrl;
      //     console.log(e);
      //   }
      // });
    },
    imgIndexChange(type) {
      if (type == "+") {
        if (this.showIndex < this.form.h5AlbumList.length - 1) {
          ++this.showIndex;
        } else {
          this.showIndex = 0;
        }
      }
      if (type == "-") {
        if (this.showIndex == 0) {
          this.showIndex = this.form.h5AlbumList.length - 1;
        } else {
          --this.showIndex;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding-top: 150px;
  margin: 0 15px;
}
.back {
  padding: 0 290px 0 290px;
  display: flex;
  align-items: center;
  line-height: 80px;
  text-align: left;
  span {
    font-size: 28px;
    cursor: pointer;
  }
  div {
    font-size: 18px;
    cursor: pointer;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.showImgBox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 120px;
  .showImg {
    display: flex;
    width: 30%;
    flex: 1;
    justify-content: left;
    flex-direction: column;
    margin: 0 20px 20px 0;
    margin-bottom: 50px;
    .text {
      text-align: left;
      margin: 15px 2px;
    }
  }
}
.youLike {
  margin: 84px 0 45px 0;
  font-size: 14px;
  font-weight: bold;
}
.changeImg {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  font-size: 30px;
  color: gray;
}
.detailInfo {
  // margin: 0 290px 0 290px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: left;
  }
  .bottomBorder {
    border-bottom: 1px solid gray;
  }
}
.bakColor {
  background-color: lightgray;
}
.colorBorder {
  border: 2px solid black !important;
}
a {
  color: black !important;
}
.toRem {
  border-radius: 50%;
  border: 2px solid gray;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.pxToRem1 {
  // width: 90%;
  // margin: 0 15px;
}
::v-deep .magnifier-box img {
  width: 100% !important;
}
.pxToRem2 {
  width: 100%;
}
.pxToRem3 {
  // width: 50%;
  // padding: 37px 0 0 67px;
}
.pxToRem4 {
  display: flex;
  flex-direction: column;
  // height: 27px;
  // border-bottom: 1px solid lightgrey;
}
.pxToRem5 {
  display: flex;
  // margin-right: 25px;
  justify-content: space-between;
  font-size: 16px;
  // padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}
.pxToRem6 {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  // padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}
.pxToRem7 {
  margin: 10px;
  text-align: left;
  font-size: 12px;
  // height: 108px;
}
.pxToRem8 {
  width: 100%;
  display: flex;
}
.pxToRem8Item {
  font-size: 14px;
}
.pxToRem9 {
  font-size: 14px;
  font-weight: bold;
}
.pxToRem10 {
  display: flex;
  margin: 9px 0 30px 0;
  align-items: center;
}
.pxToRem11 {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.pxToRem12 {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
}
.pxToRem13 {
  display: flex;
  flex-wrap: wrap;
}
.pxToRem14 {
  width: 86px;
  font-size: 12px;
  line-height: 26px;
  border: 1px solid lightgray;
  margin: 15px 10px 0 0;
  cursor: pointer;
}
.pxToRem15 {
  width: 95px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.pxToRem16 {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  span {
    margin-left: 5px;
  }
}
.pxToRem17 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.pxToRem18 {
  text-align: left;
  font-size: 13px;
}
.pxToRem19 {
  display: flex;
  width: 105px;
  flex-direction: column;
  margin-top: 30px;
  span {
    margin-left: 5px;
  }
}
.pxToRem20 {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
}
.pxToRem21 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.pxToRem22 {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 13px;
}
.pxToRem23 {
  display: flex;
  width: 105px;
  flex-direction: column;
  margin-top: 30px;
  span {
    margin-left: 5px;
  }
}
.pxToRem24 {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
}
.pxToRem25 {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.pxToRem26 {
  text-align: left;
  font-size: 13px;
}
.pxToRem27 {
  margin-top: 187px;
}
.pxToRem28 {
  margin-bottom: 20px;
}
.pxToRem29 {
  border-bottom: 2px solid black;
  font-size: 14px;
}
.pxToRem30 {
  margin-top: 13px;
  font-size: 13px;
}
.imgListBox {
  position: relative;
  width: 330px;
  height: 496px;
  background-color: white;
  img {
    left: 0;
    width: 100%;
    height: 100%;
  }
  .arrowLeftImg {
    position: absolute;
    font-size: 30px;
    height: 30px;
    top: 237px;
    bottom: 0px;
    left: 5px;
    z-index: 46;
  }
  .arrowRightImg {
    position: absolute;
    height: 30px;
    font-size: 30px;
    top: 237px;
    bottom: 0;
    right: 5px;
    z-index: 46;
  }
}
</style>
