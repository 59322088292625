<template>
  <div class="foot">
    <div @click="seeBottomInfo(2)" class="item">联系我们</div>
    <div @click="seeBottomInfo(3)" class="item">关注我们</div>
    <div @click="seeBottomInfo(4)" class="item">常见问题</div>
    <div @click="seeBottomInfo(5)" class="item">法律条款</div>
    <div @click="seeBottomInfo(6)" class="item">销售条款</div>
    <div @click="seeBottomInfo(7)" class="item">隐私声明</div>
    <div @click="seeBottomInfo('search')" class="item">中国大陆丨中文（简体）</div>
  </div>
</template>

<script>
export default {
  name: "foot",
  props: {
    msg: String,
  },
  methods: {
    seeBottomInfo(type){
        console.log(this.$route)
      if(type==this.$route.params.type || type==this.$route.name) return
      if(type=='search'){
        this.$router.push('/msearch')
        return
      }
      this.$router.push('/mbottomInfo/'+type)
    }
  }
};
</script>
<style scoped lang="scss">
.foot {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 120px 0 90px 0;
    .item {
        font-size: 12px;
        font-weight: 400;
        margin: 0 0 30px 0;
        cursor: pointer
    }
}
</style>
