<template>
  <div class="home" style="position: relative">
    <div class="head">
      <div class="headLeft">
        <div
          @mouseenter="showListBoxFn(item.id)"
          class="categories"
          v-for="item of list.categories"
          :key="item.name">
          {{ item.name }}
        </div>
        <div
          @mouseleave="showTagId = null"
          @mouseenter="showTagId = 'img'"
          :style="`border-bottom: ${
            showTagId == 'img' ? '1px solid black' : 'none'
          };`">
          <img
            @click="$router.push('/listBrand')"
            class="headImg"
            src="../../assets/BOINIGANVANITA.png" />
        </div>
      </div>
      <div class="headRight" @click="$router.push('/search')">
        <span class="search">搜索</span> <img class="pxToRem1" src="../../assets/搜索.png" />
      </div>
    </div>
    <div
      class="listBox"
      v-show="showListBox"
      @change="handleCurrentChange('1')"
      @mouseleave="showListBox = false">
      <div class="pxToRem3">
        <div
          :style="`border-bottom: ${
            item.id === showTagId ? '1px solid black' : 'none'
          };`"
          class="pxToRem4"
          v-for="item of list.categories"
          @mouseenter="getListCategory(item.id)"
          :key="item.name">
          {{ item.name }}
        </div>
        <div
          class="pxToRem5"
          @mouseleave="showTagId = null"
          @mouseenter="hoverLogo"
          :style="`border-bottom: ${
            showTagId == 'img' ? '1px solid black' : 'none'
          };`">
          <img
            @click="$router.push('/listBrand')"
            class="headImg pxToRem6"
            src="../../assets/BOINIGANVANITA.png" />
        </div>
      </div>
      <div class="pxToRem7" v-if="listCategoryArr && listCategoryArr.length">
        <div
          @click="toListSpu(item.id)"
          v-for="item of listCategoryArr.slice(0, 4)"
          class="pxToRem9">
          {{ item.name }}
        </div>
      </div>
      <div class="pxToRem8" v-if="listCategoryArr && listCategoryArr.length">
        <div
          @click="toListSpu(item.id)"
          v-for="item of listCategoryArr.slice(4)"
          class="pxToRem10">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeIndex, listCategory } from "@/apis/home.js";
import foot from "@/components/public/foot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      keyword: "",
      showTagId: "",
      list: [],
      listCategoryArr: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
      showListBox: false,
      page: { pageSize: 15, pageNum: 1, orderByColumn: "", isAsc: "" },
    };
  },
  created() {
    homeIndex().then((res) => {
      this.list = res.data.data;
    });
    listCategory().then((res) => {
      this.listCategoryArr = res.data.data;
    });
  },
  methods: {
  hoverLogo(){
    this.listCategoryArr = []
this.showTagId = 'img'
  },
    getListCategory(id) {
      this.showTagId = id;
      listCategory({ categoryId: id }).then((res) => {
        this.listCategoryArr = res.data.data.categories;
      });
    },
    showListBoxFn(id) {
      this.showTagId = id;
      this.getListCategory(id);
      this.showListBox = true;
    },
    toListSpu(id) {
      this.showListBox = false;
      if (this.$route.path == "/listSpu" && this.$route.query.id == id) return;
      this.$router.push("/listSpu?id=" + id);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  // background-color: white;
  height: 150px;
  padding: 27px 70px 0 70px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    text-align: left;
    margin-right: 29px;
    font-size: 12px;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .headImg {
    width: 73px;
    height: 10px;
    cursor: pointer;
  }
}
.headRight {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.img {
  width: 464px;
  height: 34px;
  margin: 38px auto 96px auto;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.listBox {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  bottom: 300px;
  width: 600px;
  height: 1080px;
  background: #fcfcfc;
  opacity: 1;
}
.pxToRem1 {
  width: 15px;
  margin-left: 5px;
}
.pxToRem2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pxToRem3 {
  margin: 27px 0 0 70px;
  display: flex;
}
.pxToRem4 {
  text-align: left;
  margin-right: 29px;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.pxToRem5 {
  display: flex;
  align-items: center;
}
.pxToRem6 {
  width: 73px;
  height: 10px;
  cursor: pointer;
}
.pxToRem7 {
  margin: 69px 0 0 83px;
  text-align: left;
}
.pxToRem8 {
  margin: 22px 0 0 83px;
  text-align: left;
}
.pxToRem9 {
  font-size: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.pxToRem10 {
  font-size: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.search:hover{
  box-sizing: content-box;
 border-bottom: 1px solid black;
}
</style>
