var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"detailInfo",staticStyle:{"position":"relative"}},[(_vm.showSizeTable)?_c('span',{staticClass:"el-icon-close",staticStyle:{"position":"fixed","top":"15px","right":"15px","z-index":"5","cursor":"pointer"},on:{"click":function($event){_vm.showSizeTable = false}}}):_vm._e(),(_vm.showSizeTable)?_c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","z-index":"4"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.form.sizeTableUrl}})]):_vm._e(),_c('div',{staticClass:"pxToRem1"},[_c('div',{staticClass:"imgListBox"},[_vm._l((_vm.form.h5AlbumList),function(item,index){return _c('img',{staticClass:"pxToRem2",style:(`position: absolute;z-index:${
            index == _vm.showIndex ? 45 : -1
          }`),attrs:{"src":item},on:{"touchstart":_vm.touchstart,"touchend":_vm.touchend}})}),_c('span',{staticClass:"el-icon-arrow-left arrowLeftImg",on:{"click":function($event){$event.stopPropagation();return _vm.imgIndexChange('-')}}}),_c('span',{staticClass:"el-icon-arrow-right arrowRightImg",on:{"click":function($event){$event.stopPropagation();return _vm.imgIndexChange('+')}}})],2)]),_c('div',{staticClass:"pxToRem3"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.form.name))]),_c('div',{staticClass:"pxToRem8"},[_c('div',{staticClass:"pxToRem9"},[_vm._v("颜色：")]),_vm._l((_vm.attrsMaps[1]),function(item){return _c('div',{staticClass:"pxToRem8Item"},[_vm._v(" "+_vm._s(item.name)+"/ ")])})],2),_c('div',{staticClass:"pxToRem10"},_vm._l((_vm.attrsMaps[1]),function(item){return _c('div',{staticClass:"toRem",class:{ colorBorder: _vm.color === item.name ? true : false },style:(`cursor:pointer;background-color: ${item.value}`),on:{"click":function($event){return _vm.changeImg(item.name, 1)}}})}),0),_c('div',{staticClass:"pxToRem11"},[_c('div',{staticClass:"pxToRem12"},[_vm._v("尺码范围：")]),_c('div',{staticClass:"pxToRem13"},_vm._l((_vm.attrsMaps[2]),function(item){return _c('div',{staticClass:"pxToRem14",class:{ bakColor: _vm.sizeType === item.name ? true : false },on:{"click":function($event){return _vm.changeImg(item.name, 2)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"pxToRem4"},[_c('div',{staticClass:"pxToRem5",class:{ bottomBorder: true },on:{"click":function($event){_vm.tag1 = !_vm.tag1}}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("细节")]),_c('span',{class:{
              'el-icon-arrow-down': _vm.tag1 ? false : true,
              'el-icon-arrow-up': _vm.tag1 ? true : false,
            }})]),_c('div',{staticClass:"pxToRem7"},[_vm._v(" "+_vm._s(_vm.tag1 ? _vm.form.detail : "")+" ")]),_c('div',{staticClass:"pxToRem6",class:{ bottomBorder: true },on:{"click":function($event){_vm.tag3 = !_vm.tag3}}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("成分")]),_c('span',{class:{
              'el-icon-arrow-down': _vm.tag3 ? false : true,
              'el-icon-arrow-up': _vm.tag3 ? true : false,
            }})]),_c('div',{staticClass:"pxToRem7"},[_vm._v(" "+_vm._s(_vm.tag3 ? _vm.form.components : "")+" ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"pxToRem15"},[_c('div',{staticClass:"pxToRem16",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.tag2 == '商品描述' ? (_vm.tag2 = '') : (_vm.tag2 = '商品描述')}}},[_vm._v(" 商品描述"),_c('span',{class:{
                'el-icon-arrow-down': _vm.tag2 == '商品描述' ? false : true,
                'el-icon-arrow-up': _vm.tag2 == '商品描述' ? true : false,
              }})]),(_vm.tag2 == '商品描述' && _vm.form.description)?_c('div',{staticClass:"pxToRem17"},_vm._l((_vm.form.description.split('\r\n')),function(item){return _c('div',{staticClass:"pxToRem18"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"pxToRem19"},[_c('div',{staticClass:"pxToRem20",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.tag2 == '尺码与版型' ? (_vm.tag2 = '') : (_vm.tag2 = '尺码与版型')}}},[_vm._v(" 尺码与版型"),_c('span',{class:{
                'el-icon-arrow-down': _vm.tag2 == '尺码与版型' ? false : true,
                'el-icon-arrow-up': _vm.tag2 == '尺码与版型' ? true : false,
              }})]),(_vm.tag2 == '尺码与版型')?_c('div',{staticClass:"pxToRem21"},[_c('div',{staticClass:"pxToRem22"},[_c('div',[_vm._v(_vm._s(_vm.form.sizeType))]),(_vm.form.sizeTableUrl)?_c('div',{staticStyle:{"border-bottom":"1px solid black","cursor":"pointer","margin-top":"5px"},on:{"click":function($event){_vm.showSizeTable = true}}},[_vm._v(" 尺码参考表 ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"pxToRem23"},[_c('div',{staticClass:"pxToRem24",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.tag2 == '面料与保养' ? (_vm.tag2 = '') : (_vm.tag2 = '面料与保养')}}},[_vm._v(" 面料与保养"),_c('span',{class:{
                'el-icon-arrow-down': _vm.tag2 == '面料与保养' ? false : true,
                'el-icon-arrow-up': _vm.tag2 == '面料与保养' ? true : false,
              }})]),(_vm.tag2 == '面料与保养' && _vm.form.fabricCare)?_c('div',{staticClass:"pxToRem25"},_vm._l((_vm.form.fabricCare.split('\r\n')),function(item){return _c('div',{staticClass:"pxToRem26"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }