<template>
  <div class="home">
    <div
      class="pic">
      <div style="cursor: pointer;position: relative;" class="pic1" @click="toList(0)">
        <img :src="list.homes[0].pictUrl" />
        <div class="seeDetail">
          <!-- <div style="border-bottom: 1px solid white">立即查看</div> -->
        </div>
      </div>
      <div class="pic2" :style="`background-image: url(${list.homes[1].background});background-size: 100% auto;`">
        <div class="top">
          <div class="pxToRem1">
            {{ list.homes[1].name }}
          </div>
          <div class="pxToRem2">
            {{ list.homes[1].subName }}
          </div>
          <div><span class="pxToRem3" @click="toList(1)">立即查看</span></div>
        </div>
        <div class="center">
          <div
            :class="index == mouseenterIndex ? 'text1' : 'text2'"
            style="cursor: pointer;"
            v-for="(item, index) of list.homes[1].itemList">
            <span @click="$router.push('/detail/' + item.configId)" @mouseenter="mouseenterFn(index)">{{ item.configName }}</span>
          </div>
        </div>
        <div class="bottom">
          <img style="cursor: pointer;" @click="$router.push('/detail/' + list.homes[1].itemList[mouseenterIndex].configId)" :src="list.homes[1].itemList[mouseenterIndex].pictUrl" />
        </div>
      </div>
      <div
        class="pic3"
        :style="`background-image: url(${list.homes[2].background});background-size: 100% auto;`">
        <div class="bottom">
          <div v-for="(item, index) of list.homes[2].itemList">
            <img
            style="cursor: pointer"
              @click="$router.push(`/show?id=${item.configId}`)"
              v-if="showImgIndex == index"
              :src="item.pictUrl" />
            <div class="imgText" v-if="showImgIndex == index">
              {{ item.configName }}
            </div>
            <div class="changeIcon" v-if="showImgIndex == index">
              <span style="cursor: pointer" @click="changeImg(1)" class="jtz el-icon-back"></span>
              <span style="cursor: pointer" @click="changeImg(2)" class="jty el-icon-right"></span>
            </div>
            <div
              class="ts"
              v-if="showImgIndex == index"
              @click="$router.push(`/show?id=${item.configId}`)">
              <span class="pxToRem4">{{ list.homes[2].configJumps.configName }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <img v-if="item.pictUrl" :src="item.pictUrl" />
      <div v-else-if="item.itemList">
       <img v-for="item2 of item.itemList" :key="item2.pictUrl"  :src="item2.pictUrl" />
      </div> -->
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { homeIndex } from "@/apis/home.js";
import foot from "@/components/public/foot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      list: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
    };
  },
  created() {
    homeIndex().then((res) => {
      this.list = res.data.data;
    });
  },
  methods: {
    mouseenterFn(index) {
      this.mouseenterIndex = index;
    },
    toList(index){
      const id = this.list.homes[index].configJumps.configId
      if(id){
        this.$router.push('/listSpu?id='+this.list.homes[index].configJumps.configId)
      } else {
        this.$router.push('/listSpu')
      }
    },
    changeImg(n) {
      if (n == 2) {
        if (this.showImgIndex == this.list.homes[2].itemList.length - 1) {
          this.showImgIndex = 0;
          return;
        }
        this.showImgIndex = this.showImgIndex + 1;
      } else {
        if (this.showImgIndex == 0) {
          this.showImgIndex = this.list.homes[2].itemList.length - 1;
          return;
        }
        this.showImgIndex = this.showImgIndex - 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  margin: 0 70px 0 70px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic1 {
    font-size: 17px;
    font-weight: 400;
    color: white;
    img {
      width: 100%;
    }
  }
  .pic2 {
    padding: 24px 31px 0 31px;
    margin-top: 40px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -34px 0 0 0;
      .text1 {
        font-size: 75px;
        font-weight: 400;
        line-height: 100px;
      }
      .text2 {
        font-size: 33px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 50px;
      }
    }
    .bottom {
      img {
        width: 626px;
        margin: 134px auto 134px auto;
      }
    }
  }
  .pic3 {
    margin-top:35px;
    .bottom {
      padding-top: 53px;
    }
    img {
      width: 1000px;
      height: 600px;
    }
    .imgText {
      width: 1000px;
      color: white;
      text-align: left;
      font-size: 33px;
      font-weight: bold;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 1000px;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 1000px;
      color: white;
      text-align: left;
      font-weight: 400;
      font-size: 17px;
      margin: 15px auto 0 auto;
      padding-bottom: 40px;
      cursor: pointer
    }
  }
}
.pxToRem1 {
font-size: 33px; font-weight: bold; margin-bottom: 13px
}
.pxToRem2 {
  font-size: 17px;font-weight: 400; margin-bottom: 27px
}
.pxToRem3 {
  font-size: 17px;font-weight: 300;border-bottom: 1px solid black;cursor: pointer
}
.pxToRem4 {
  border-bottom: 1px solid white;
}
.seeDetail {
  position: absolute;
  top: 474px;
  right: 0;
  left: 0;
  div {
    width: 70px;
    margin: auto;
    padding-bottom: 5px;
  }
}
</style>
