<template>
  <div class="home">
    <!-- <el-dialog
      :center="true"
      :modal-append-to-body="false"
      :visible.sync="dialogVisible"
      width="100%"
      :before-close="handleClose"> -->
    <video-player
      v-show="dialogVisible"
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      @loadeddata="onLoadedData"
      :options="playerOptions"></video-player>
    <!-- <video
       ref="video"
        v-if="dialogVisible"
        autoplay
        width="100%"
        controls
        :src="videoUrl"></video> -->
    <!-- </el-dialog> -->

    <div class="showImgBox">
      <div class="seeDetail" v-if="videoUrl">
        <div
          style="border-bottom: 1px solid white; cursor: pointer"
          @click="watchingFashionShows">
          观看时装秀
        </div>
      </div>
      <img
        @click="watchingFashionShows"
        :src="headImg"
        class="pxToRem1"
        style="cursor: pointer" />
      <div
        class="showImg"
        v-for="(item, index) of listCatwalks"
        :class="{ 'margin-right0': (index + 1) % 4 == 0 }">
        <img @click="showImgFn(index)" :src="item.pictUrl" />
        <div class="text">{{ item.name }}/{{ total }}</div>
      </div>

      <div class="pxToRem2">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="showImgBox" style="padding-top: 0">
      <div
        class="pxToRem3"
        v-for="(item, index) of newGetCatwalks"
        :key="item"
        :class="{ 'margin-right0': (index + 1) % 3 == 0 }">
        <img style="width: 100%" :src="item" />
      </div>
    </div>
    <div class="changeImg">
      <div
        style="cursor: pointer"
        class="el-icon-arrow-left"
        @click="pageIndex--"></div>
      <div
        style="cursor: pointer"
        class="el-icon-arrow-right"
        @click="pageIndex++"></div>
    </div>

    <div
      v-if="dialogVisible2"
      @click="dialogVisible2 = false"
      style="
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 99999;
      ">
      <img style="height: 100%" :src="listCatwalks[showIndex].pictUrl" />
      <div style="position: fixed; bottom: 50px; left: 50px">
        <span
          @click.stop="imgIndexChange('-')"
          style="color: white; margin-right: 30px"
          class="el-icon-back"></span>
        <span
          @click.stop="imgIndexChange('+')"
          style="color: white"
          class="el-icon-right"></span>
      </div>
      <div style="position: fixed; bottom: 50px; right: 50px">
        <span style="color: white"
          >{{ showIndex + 1 }}/{{ listCatwalks.length }}</span
        >
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { listCatwalksLook, getCatwalks } from "@/apis/home.js";
import foot from "@/components/public/foot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      zkh: "<",
      ykh: ">",
      keyword: "",
      list: [],
      showIndex: 0,
      dialogVisible: false,
      dialogVisible2: false,
      listCatwalks: [],
      getCatwalks: [],
      headImg: "",
      mouseenterIndex: 0,
      showImgIndex: 0,
      total: 0,
      pageIndex: 1,
      videoUrl: "",
      page: { pageSize: 16, pageNum: 1, orderByColumn: "", isAsc: "" },
    };
  },
  computed: {
    newGetCatwalks() {
      const page = Math.ceil(this.getCatwalks.length / 3);
      const a = this.pageIndex;
      if (this.pageIndex > page) {
        this.pageIndex = 1;
      }
      if (this.pageIndex < 1) {
        this.pageIndex = page;
      }
      if (this.pageIndex == 1) {
        return this.getCatwalks.slice(0, 3);
      }
      let arr = this.getCatwalks.slice(
        (this.pageIndex - 1) * 3 - 1,
        (this.pageIndex - 1) * 3 - 1 + 3
      );
      if (arr.length == "1") {
        arr = [...arr, this.getCatwalks[0], this.getCatwalks[1]];
      }
      if (arr.length == "2") {
        arr = [...arr, this.getCatwalks[0]];
      }
      return arr;
    },
    playerOptions() {
      if (!this.videoUrl) return {};
      const _this = this;
      const playerOptionsObj = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
        autoplay: false, // 如果true，浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
        language: "zh-CN",
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
            src: _this.videoUrl, // 视频url地址
          },
        ],
        poster: _this.videoUrl, // 视频封面地址
        // width: document.documentElement.clientWidth,
        width: 1200,
        height: 675, // 设置高度，fluid需要设置成flase
        notSupportedMessage: "此视频暂无法播放...", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      };
      return playerOptionsObj;
    },
  },
  created() {
    // homeIndex().then((res) => {
    //   this.list = res.data.data;
    // });
    const id = this.$route.query.id;
    const params = {
      ...this.page,
      catwalksId: id,
    };
    listCatwalksLook(params).then((res) => {
      this.listCatwalks = res.data.rows;
      this.total = res.data.total;
    });
    getCatwalks({ catwalksId: id }).then((res) => {
      this.headImg = res.data.data.pictUrl;
      this.videoUrl = res.data.data.videoUrl;
      this.getCatwalks = res.data.data.albumList;
    });
  },
  methods: {
    watchingFashionShows() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.requestFullscreen();
        this.$refs.videoPlayer.player.load();
        this.$refs.videoPlayer.player.play();
      });
    },
    onLoadedData(player) {
      player.on("fullscreenchange", () => {
        if (!player.isFullscreen_) {
          this.$refs.videoPlayer.player.pause();
          this.dialogVisible = false;
        }
      });
    },
    imgIndexChange(type) {
      if (type == "+") {
        if (this.showIndex < this.listCatwalks.length - 1) {
          ++this.showIndex;
        } else {
          this.showIndex = 0;
        }
      }
      if (type == "-") {
        if (this.showIndex == 0) {
          this.showIndex = this.listCatwalks.length - 1;
        } else {
          --this.showIndex;
        }
      }
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      const id = this.$route.query.id;
      const params = {
        ...this.page,
        catwalksId: id,
      };
      listCatwalksLook(params).then((res) => {
        this.listCatwalks = res.data.rows;
        this.total = res.data.total;
      });
    },
    showImgFn(index) {
      this.showIndex = index;
      this.dialogVisible2 = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  // padding: 0 20px;
  margin: 0 0 0 0;
}
::v-deep .el-dialog {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: none;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  font-size: 20px;
}
::v-deep .el-dialog__body {
  padding-bottom: 0 !important;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.showImgBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 160px 0 160px;
  .showImg {
    display: flex;
    width: 20%;
    max-width: calc((100% - 81px) / 4);
    flex: 1;
    justify-content: left;
    flex-direction: column;
    margin-right: 27px;
    // margin-bottom: 50px;
    .text {
      text-align: left;
      font-size: 18px;
      margin: 20px 5px 94px 0;
    }
  }
}
.changeImg {
  display: flex;
  justify-content: space-between;
  padding: 14px 189px 0 189px;
  font-size: 25px;
  color: gray;
}
.pxToRem1 {
  width: 100%;
  margin-bottom: 86px;
}
.pxToRem2 {
  width: 100% !important;
  margin: 0 auto 160px auto !important;
}
.pxToRem3 {
  width: 30%;
  flex: 1 1 30%;
  margin-right: 50px;
  margin-bottom: 0 !important;
}
.margin-right0 {
  margin-right: 0 !important;
}
.seeDetail {
  position: absolute;
  top: 930px;
  right: 0;
  left: 0;
  div {
    color: white;
    width: 80px;
    margin: auto;
    padding-bottom: 5px;
  }
}
</style>
