import axios from "./request";

const baseUrl = "/portal/";
// const baseUrl = "/";

export const homeIndex = () => {
  return axios({
    url: `${baseUrl}home/index`,
    method: "get",
  });
};

export const listCatwalksLook = (param) => {
  return axios({
    url: `${baseUrl}catwalks/listCatwalksLook`,
    method: "get",
    params: param,
  });
};

export const getCatwalks = (param) => {
  return axios({
    url: `${baseUrl}catwalks/getCatwalks`,
    method: "get",
    params: param,
  });
};

export const listBrand = (param) => {
  return axios({
    url: `${baseUrl}brand/listBrand`,
    method: "get",
    params: param,
  });
};

export const listSpu = (param) => {
  return axios({
    url: `${baseUrl}spu/listSpu`,
    method: "post",
    data: param,
  });
};

export const getAttrs = (param) => {
  return axios({
    url: `${baseUrl}spu/getAttrs`,
    method: "get",
    params: param,
  });
};

export const getSpu = (param) => {
  return axios({
    url: `${baseUrl}spu/getSpu`,
    method: "get",
    params: param,
  });
};

export const listClause = (param) => {
  return axios({
    url: `${baseUrl}common/listClause`,
    method: "get",
    params: param,
  });
};

export const listPrivacy = (param) => {
  return axios({
    url: `${baseUrl}common/listPrivacy`,
    method: "get",
    params: param,
  });
};

export const listFollowUs = (param) => {
  return axios({
    url: `${baseUrl}common/listFollowUs`,
    method: "get",
    params: param,
  });
};

export const listContactUs = (param) => {
  return axios({
    url: `${baseUrl}common/listContactUs`,
    method: "get",
    params: param,
  });
};

export const listTermsSale = (param) => {
  return axios({
    url: `${baseUrl}common/listTermsSale`,
    method: "get",
    params: param,
  });
};

export const listCategory = (param) => {
  return axios({
    url: `${baseUrl}home/listCategory`,
    method: "get",
    params: param,
  });
};

export const listProblem = (param) => {
  return axios({
    url: `${baseUrl}common/listProblem`,
    method: "get",
    params: param,
  });
};



















