// 基准大小
const baseSize = 16;
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iphone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQOBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOs|Symbian|Windows Phone)/i
  );
  const width = flag?360:1920
  const scale = document.documentElement.clientWidth / width;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
