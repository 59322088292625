<template>
  <div class="home">
    <div class="pxToRem1" v-show="!showScreen">
      <div>
        <div class="pxToRem2">{{ title }}</div>
        <div class="pxToRem3">
          <div class="pxToRem4">
            <span @click="showScreen = true">筛选</span>|
          </div>
          <div class="pxToRem8 orderNum">
            <el-select
              collapse-tags
              class="selectWidth4"
              style="flex: 1"
              v-model="orderNum"
              placeholder="排序/默认"
              @change="handleCurrentChange('1')">
              <el-option
                v-for="item in orderNumDic"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="pxToRem9">
        <div
          v-for="(item, index) of listSpu"
          :key="index"
          :style="`width: 100%;display: flex`">
          <div class="pxToRem10">
            <img
              :class="{ marginLeftNone: (index2 + 1) % 2 != 0 }"
              v-for="(item2, index2) of item"
              v-if="(index2 != 4 && item2.h5ListPictUrl) || item2.h5ModelUrl"
              @click="$router.push('/mdetail/' + item2.id)"
              class="pxToRem12"
              :src="index2 != 4 ? item2.h5ListPictUrl : item2.h5ModelUrl" />
          </div>
        </div>
      </div>
      <div class="pxToRem22">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="screen" v-show="showScreen">
      <div class="head">
        <div class="headLeft">
          <span @click="clearSelect()">清除筛选</span>
        </div>
        <div class="headRight el-icon-close" @click="showScreen = false"></div>
      </div>
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item title="颜色" name="1">
          <van-checkbox-group v-model="color" direction="horizontal">
            <van-checkbox
              shape="square"
              @click="selectItem(1)"
              v-for="item of Attrs['1'].map((e) => ({
                value: e.name,
                label: e.name,
              }))"
              :name="item.value"
              >{{ item.value }}</van-checkbox
            >
          </van-checkbox-group>
          <!-- <div v-for="item of Attrs['1'].map((e) => ({ value: e.name, label: e.name }))">{{ item.value }}</div> -->
        </van-collapse-item>
        <van-collapse-item title="尺码" name="2">
          <van-checkbox-group v-model="size" direction="horizontal">
            <van-checkbox
              shape="square"
              @click="selectItem(2)"
              v-for="item of Attrs['2'].map((e) => ({
                value: e.name,
                label: e.name,
              }))"
              :name="item.value"
              >{{ item.value }}</van-checkbox
            >
          </van-checkbox-group>
        </van-collapse-item>
        <van-collapse-item title="款式" name="3">
          <van-checkbox-group v-model="style" direction="horizontal">
            <van-checkbox
              shape="square"
              @click="selectItem(3)"
              v-for="item of Attrs['3'].map((e) => ({
                value: e.name,
                label: e.name,
              }))"
              :name="item.value"
              >{{ item.value }}</van-checkbox
            >
          </van-checkbox-group>
        </van-collapse-item>
        <van-collapse-item title="材质" name="4">
          <van-checkbox-group v-model="materialQuality" direction="horizontal">
            <van-checkbox
              shape="square"
              @click="selectItem(4)"
              v-for="item of Attrs['4'].map((e) => ({
                value: e.name,
                label: e.name,
              }))"
              :name="item.value"
              >{{ item.value }}</van-checkbox
            >
          </van-checkbox-group>
        </van-collapse-item>
      </van-collapse>
      <div class="showAll">
        <span class="sure" @click="seachList">确认</span>
        <span class="showAll2" @click="clearSelect(true)">显示全部</span>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { listSpu, getAttrs } from "@/apis/home.js";
import foot from "@/components/public/mfoot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      activeNames: ["1"],
      keyword: "",
      listSpu: [],
      list: [],
      color: [],
      size: [],
      style: [],
      materialQuality: [],
      orderNum: "1",
      orderNumDic: [
        {
          label: "排序/默认",
          value: "1",
        },
        {
          label: "最新上架",
          value: "2",
        },
        {
          label: "价格从低至高",
          value: "3",
        },
        {
          label: "价格从高至低",
          value: "4",
        },
      ],
      mouseenterIndex: 0,
      showImgIndex: 0,
      Attrs: [],
      categoryId: null,
      total: 0,
      title: "",
      page: { pageSize: 15, pageNum: 1, orderByColumn: "", isAsc: "" },
      showScreen: false,
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route() {
      this.categoryId = this.$route.query.id;
      this.handleCurrentChange(1);
    },
  },
  created() {
    // homeIndex().then((res) => {
    //   this.list = res.data.data;
    // });
    this.categoryId = this.$route.query.id;
    const params = {
      categoryId: this.categoryId,
      keyword: this.$route.query ? this.$route.query.keyword : null,
      orderNum: this.orderNum,
      ...this.page,
    };
    listSpu(params).then((res) => {
      this.listSpu = [];
      let arr = [];
      const len = res.data.rows.length;
      res.data.rows.forEach((e, index) => {
        arr.push(e);
        if ((index + 1) % 5 == 0 || index + 1 == len) {
          this.listSpu.push([...arr]);
          arr = [];
        }
      });
      this.total = res.data.total;
      this.title = res.data.title;
    });
    getAttrs().then((res) => {
      this.Attrs = res.data.data.attrsMap;
    });
  },
  methods: {
    seachList (){
      this.handleCurrentChange(1)
      this.showScreen=false
    },
    selectItem(index) {
      if (index != 1 && this.color.length) {
        this.color = [];
      }
      if (index != 2 && this.size.length) {
        this.size = [];
      }
      if (index != 3 && this.style.length) {
        this.style = [];
      }
      if (index != 4 && this.materialQuality.length) {
        this.materialQuality = [];
      }
      // this.handleCurrentChange("1");
    },
    clearSelect(close) {
      this.color = [];
      this.size = [];
      this.style = [];
      this.materialQuality = [];
      // this.orderNum = "1";
      if (close) {
        this.showScreen = false;
        if(this.$route.fullPath!=='/mlistSpu'){
        this.$router.push('/mlistSpu')
        } else {
          this.handleCurrentChange(1);
        }
      }
      // this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      // {  "categoryId": 0,  "keyword": "",  "colour": "",  "sizes": "",  "styles": "",  "material": ""}
      this.page.pageNum = val;
      const params = {
        colour: this.color.flat(Infinity),
        sizes: this.size.flat(Infinity),
        styles: this.style.flat(Infinity),
        material: this.materialQuality.flat(Infinity),
        orderNum: this.orderNum,
        categoryId: this.categoryId,
        keyword: this.$route.query ? this.$route.query.keyword : null,
        ...this.page,
      };
      listSpu(params).then((res) => {
        this.title = res.data.title
        this.listSpu = [];
        const a = this.listSpu
        let arr = [];
        const len = res.data.rows.length;
        res.data.rows.forEach((e, index) => {
          arr.push(e);
          if ((index + 1) % 5 == 0 || index + 1 == len) {
            this.listSpu.push([...arr]);
            arr = [];
          }
        });
        this.total = res.data.total;
        window.scrollTo(0, 0)
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding-top:150px
}
::v-deep .van-checkbox {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 10px;
  width: 45%;
}
::v-deep .van-cell__title {
  display: flex;
}
::v-deep .el-select .el-input__inner {
  border: none;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0);
}
::v-deep .el-select .el-input__inner {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

::v-deep .el-input__inner {
  font-size: 18px;
}
::v-deep .el-select {
  width: 120px;
  height: 30px;
  overflow: hidden;
}
::v-deep .el-cascader .el-input .el-cascader__caret {
  color: gray;
  font-size: 19px;
}
::v-deep .el-input__suffix {
  display: none;
}
::v-deep .el-cascader__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.home {
  // padding: 0 20px;
  // margin: 211px 0 0 0;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 15px 40px 15px;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  font-size: 12px;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.pxToRem1 {
  margin: 0 12px;
}
.pxToRem2 {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.pxToRem3 {
  display: flex;
  padding-top: 5px;
  border-bottom: 2px solid lightgray;
}
.pxToRem4 {
  margin-left: 5px;
  font-size: 12px;
  line-height: 40px;
  span {
    margin-right: 15px;
  }
}
.clearSelect {
  height: 40px;
  margin-right: 770px;
}
.pxToRem5 {
  margin-right: 40px;
  font-size: 18px;
}
.pxToRem6 {
  margin-right: 40px;
  font-size: 18px;
}
.pxToRem7 {
  margin-right: 40px;
  font-size: 18px;
}
.pxToRem8 {
  margin-right: 40px;
  font-size: 18px;
}
.orderNum {
  margin-right: 0;
}
.pxToRem9 {
  width: 100%;
  margin-top: 20px;
}
.pxToRem10 {
  display: flex;
  // width: 40%;
  flex-wrap: wrap;
  flex: 1 1 100%;
  img {
    margin: 0 0 30px 12px;
  }
  .marginLeftNone {
    margin-left: 0;
  }
}
.pxToRem11 {
  display: flex;
  flex: 1 1 80%;
}
.pxToRem12 {
  flex: 1 1 30%;
  width: 40%;
  margin: 0 34px 50px 0;
  cursor: pointer;
}
.pxToRem13 {
  flex: 1 1 30%;
  width: 40%;
  margin: 0 0 50px 0;
  cursor: pointer;
}
.pxToRem14 {
  flex: 1 1 30%;
  width: 40%;
  margin: 0 34px 50px 0;
  cursor: pointer;
}
.pxToRem15 {
  display: flex;
  flex: 1 1 80%;
}
.pxToRem16 {
  flex: 1 1 30%;
  width: 40%;
  margin: 50px 34px 50px 0;
  cursor: pointer;
}
.pxToRem17 {
  flex: 1 1 30%;
  width: 40%;
  margin: 50px 0 50px 0;
  cursor: pointer;
}
.pxToRem18 {
  flex: 1 1 30%;
  width: 40%;
  margin: 50px 34px 50px 0;
  cursor: pointer;
}
.pxToRem19 {
  flex: 1 1 40%;
  width: 40%;
  display: flex;
  justify-content: left;
}
.pxToRem20 {
  flex: 1;
  width: calc(100% - 34px);
  height: calc(100% - 50px);
  margin-right: 34px;
  cursor: pointer;
}
.pxToRem21 {
  flex: 1;
  width: 100%;
  height: calc(100% - 50px);
  cursor: pointer;
}
.pxToRem22 {
  width: 100%;
  margin: 0 auto 0 auto;
}
.pxToRem23 {
  margin-right: 33px;
}
.pxToRem24 {
  width: calc(25% - 34px);
}
.selectWidth1 {
  // width: 165px;
}
.selectWidth2 {
  // width: 200px;
}
.selectWidth3 {
  // width: 160px;
}
.selectWidth4 {
  width: 120px;
}
::v-deep .selectWidth4 .el-input__inner {
  text-align: left;
}
::v-deep input::-webkit-input-placeholder {
  color: #000;
}
input::-moz-input-placeholder {
  color: #000;
}
input::-ms-input-placeholder {
  color: #000;
}
.selectTitle {
  position: absolute;
  top: 7px;
  left: 18px;
  z-index: -1;
}
::v-deep .el-cascader__tags {
  display: none;
}
.screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 55;
}
.showAll {
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 15px;
  right: 15px;
  font-size: 14px;
  .sure {
    width: 50%;
    margin-right: 5px;
    line-height: 36px;
    border: 1px solid black;
  }
  .showAll2 {
    width: 49%;
    line-height: 36px;
    border: 1px solid black;
  }
}
</style>
