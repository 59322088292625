import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./js/pxToRem.js";
//入口文件中全局引入
import vuePicturePreview from 'vue-picture-preview';
import VideoPlayer from "vue-video-player/src";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import { Button } from "vant";
import { Collapse, CollapseItem } from "vant";
import "vant/lib/index.css";
import "vant/lib/button/style";
import { Checkbox, CheckboxGroup } from "vant";
import { Cell, CellGroup } from "vant";
// 引入js
import Swiper from "swiper";
// 引入css
import "swiper/css/swiper.min.css";
Vue.use(Cell);
Vue.use(CellGroup);

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(VideoPlayer);



Vue.component('Previewer', vuePicturePreview);

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
