import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import search from "../views/search.vue";
import show from "../views/show.vue";
import listBrand from "../views/listBrand.vue";
import listSpu from "../views/listSpu.vue";
import detail from "../views/detail.vue";
import bottomInfo from "../views/bottomInfo.vue";

import mHomeView from "../views/mHomeView.vue";
import msearch from "../views/msearch.vue";
import mshow from "../views/mshow.vue";
import mlistBrand from "../views/mlistBrand.vue";
import mlistSpu from "../views/mlistSpu.vue";
import mdetail from "../views/mdetail.vue";
import mbottomInfo from "../views/mbottomInfo.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/search",
    name: "search",
    component: search,
  },
  {
    path: "/show",
    name: "show",
    component: show,
  },
  {
    path: "/listBrand",
    name: "listBrand",
    component: listBrand,
  },
  {
    path: "/listSpu",
    name: "listSpu",
    component: listSpu,
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: detail,
  },
  {
    path: "/bottomInfo/:type",
    name: "bottomInfo",
    component: bottomInfo,
  },

  {
    path: "/mhome",
    name: "mhome",
    component: mHomeView,
  },
  {
    path: "/msearch",
    name: "msearch",
    component: msearch,
  },
  {
    path: "/mshow",
    name: "mshow",
    component: mshow,
  },
  {
    path: "/mlistBrand",
    name: "mlistBrand",
    component: mlistBrand,
  },
  {
    path: "/mlistSpu",
    name: "mlistSpu",
    component: mlistSpu,
  },
  {
    path: "/mdetail/:id",
    name: "mdetail",
    component: mdetail,
  },
  {
    path: "/mbottomInfo/:type",
    name: "mbottomInfo",
    component: mbottomInfo,
  },
];

const router = new VueRouter({
  routes,
});

// 跳转后返回顶部
 router.beforeEach((to,from,next) => {
      window.scrollTo(0,0);
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iphone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQOBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOs|Symbian|Windows Phone)/i
      );
      if (flag){
        if (to.path === "/") {
          next("/mhome");
          return;
        }
        if (to.path[1] == "m") {
          next();
        } else {
          const url = to.path.replace("/", "/m");
          next(url);
        }
      } else {
        if (to.path[1] != "m") {
          next();
        } else {
          const url = to.path.replace("/m", "/");
          next(url);
        }
      }
 })

export default router;
