<template>
  <div class="home">
    <div class="pxToRem1">
      <div class="pxToRem2">
        {{ typeArr[type] }}
      </div>
      <div v-for="item of list">
        <div class="pxToRem3">
          <div>
            {{ item.name || item.question }}
          </div>
          <div
            @click="item.show = !item.show"
            style="font-weight: bold; cursor: pointer"
            :class="{
              'el-icon-minus': item.show,
              'el-icon-plus': !item.show,
            }"></div>
        </div>
        <div
        class="info"
          v-if="item.show && !item.pictUrl"
          style="text-align: left"
          v-html="item.content || item.resolves"></div>
        <div v-if="item.show && item.pictUrl">
          <img width="100%" :src="item.pictUrl" />
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import {
  listClause,
  listPrivacy,
  listFollowUs,
  listContactUs,
  listTermsSale,
  listProblem,
} from "@/apis/home.js";
import foot from "@/components/public/mfoot";
export default {
  name: "HomeView",
  components: {
    foot,
  },
  data() {
    return {
      type: "",
      keyword: "",
      list: [],
      follows: [],
      mouseenterIndex: 0,
      showImgIndex: 0,
      typeArr: [
        "",
        "中国大陆|中文简体",
        "联系我们",
        "关注我们",
        "常见问题",
        "法律条款",
        "销售条款",
        "隐私声明",
      ],
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route() {
      this.getInfo();
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.type = this.$route.params.type;
      switch (this.type) {
        case "1":
          listClause().then((res) => {
            res.data.data.clauses.forEach((e) => (e.show = false));
            this.list = res.data.data.clauses;
          });
          break;
        case "2":
          listContactUs().then((res) => {
            res.data.data.contacts.forEach((e) => (e.show = false));
            this.list = res.data.data.contacts;
          });
          break;
        case "3":
          listFollowUs().then((res) => {
            res.data.data.follows.forEach((e) => (e.show = false));
            this.list = res.data.data.follows;
          });
          break;
        case "4":
          listProblem().then((res) => {
            res.data.data.problems.forEach((e) => (e.show = false));
            this.list = res.data.data.problems;
          });
          break;
        case "5":
          listClause().then((res) => {
            res.data.data.clauses.forEach((e) => (e.show = false));
            this.list = res.data.data.clauses;
          });
          break;
        case "6":
          listTermsSale().then((res) => {
            res.data.data.sales.forEach((e) => (e.show = false));
            this.list = res.data.data.sales;
          });
          break;
        case "7":
          listPrivacy().then((res) => {
            res.data.data.privacies.forEach((e) => (e.show = false));
            this.list = res.data.data.privacies;
          });
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding-top: 150px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headLeft {
  display: flex;
  align-items: center;
  width: 30%;
  .categories {
    margin: 0 15px 0 0;
  }
  .headImg {
    width: 115px;
    height: 12px;
  }
}
.headRight {
  display: flex;
  align-items: center;
}
.img {
  width: 100%;
  margin: 30px 0 50px 0;
}
.pic {
  width: 100%;
  img {
    width: 100%;
  }
  .pic2 {
    padding: 15px;
    margin-top: 30px;
    .top {
      display: flex;
      justify-content: left;
      flex-direction: column;
      div {
        text-align: left;
      }
    }
    .center {
      margin: -26px 0 80px 0;
      .text1 {
        font-size: 60px;
        font-weight: 5000;
        line-height: 100px;
      }
      .text2 {
        font-size: 25px;
        font-weight: 450;
        margin-bottom: 15px;
        line-height: 25px;
      }
    }
    .bottom {
      img {
        width: 35%;
        margin: auto;
      }
    }
  }
  .pic3 {
    .bottom {
      padding-top: 20px;
    }
    img {
      width: 51%;
    }
    .imgText {
      width: 51%;
      color: white;
      text-align: left;
      font-size: 30px;
      font-weight: 500;
      margin: 10px auto 0 auto;
    }
    .changeIcon {
      width: 51%;
      color: white;
      text-align: left;
      margin: -8px auto 0 auto;
      display: flex;
      justify-content: right;
      font-size: 20px;
      font-weight: 1000;
      .jty {
        margin-left: 15px;
      }
    }
    .ts {
      width: 51%;
      color: white;
      text-align: left;
      font-weight: 500;
      margin: 0 auto 0 auto;
      padding-bottom: 40px;
    }
  }
}
.pxToRem1 {
  padding-top: 50px;
  // width: 200px;
  margin: auto 30px;
}
.pxToRem2 {
  font-size: 14px;
  height: 30px;
  font-weight: bold;
  border-bottom: 1px solid black;
  text-align: left;
}
.pxToRem3 {
  text-align: left;
  font-size: 14px;
  margin: 15px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.info {
  font-size: 12px;
}
</style>
